import { useRef, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  path,
  any,
  props,
  pathOr,
  pipe,
  has,
  ifElse,
  cond,
  length,
  equals,
  prop,
  head,
  __,
  T,
  reduce,
  propOr,
  includes,
  anyPass,
} from 'ramda';
import { useSelector } from 'react-redux';

import { useQuery } from 'api';
import { isAuth } from 'containers/authorization/selectors';

import { GET_COURSES_AND_PROGRAMS, GET_POSTS, GET_COURSE_PROGRESSES } from './gqls';
import { PAGINATION_LIMIT } from './consts';
import { splitPathname, normalize, getStaticMetaData } from './utils';

const usePublicRequest = () => {
  const limit = useRef(PAGINATION_LIMIT);

  const coursesAndPrograms = useQuery(GET_COURSES_AND_PROGRAMS, { fetchPolicy: 'cache-first' });
  const posts = useQuery(GET_POSTS, {
    variables: { limit: limit.current },
    fetchPolicy: 'cache-first',
  });

  const totalItems = useMemo(() => path(['data', 'posts', 'pageInfo', 'total'], posts || {}), [posts]);

  useEffect(() => {
    if (totalItems && limit.current < totalItems) {
      posts.fetchMore({ variables: { limit: totalItems } });
      limit.current = totalItems;
    }
  }, [posts, posts.fetchMore, totalItems]);

  const loading = useMemo(
    () =>
      any(Boolean, [
        ...props(['loading', 'error'], coursesAndPrograms),
        ...props(['loading', 'error'], posts),
        limit.current < totalItems,
      ]),
    [coursesAndPrograms, posts, totalItems]
  );

  return useMemo(
    () => ({
      courses: pipe(pathOr([], ['data', 'courses']), normalize)(coursesAndPrograms),
      programs: pipe(pathOr([], ['data', 'programs']), normalize)(coursesAndPrograms),
      posts: pipe(pathOr([], ['data', 'posts', 'edges']), normalize)(posts),
      loading,
    }),
    [coursesAndPrograms, posts, loading]
  );
};

const useRequest = () => {
  const publicData = usePublicRequest();
  const { pathname } = useLocation();
  const hasAuth = useSelector(isAuth);

  const courseProgressId = useMemo(
    () => ifElse(pipe(props([0, 1]), equals(['akademie', 'kurse'])), prop(2), () => null)(splitPathname(pathname)),
    [pathname]
  );

  const { loading, error, data, refetch } = useQuery(GET_COURSE_PROGRESSES, { fetchPolicy: 'cache-first', skip: !hasAuth });

  const shouldFetchMore = useMemo(
    () => hasAuth && courseProgressId && (!data || !has('courseProgressId', data)),
    [courseProgressId, data, hasAuth]
  );

  useEffect(() => {
    if (shouldFetchMore) refetch();
  }, [shouldFetchMore, refetch]);

  return useMemo(
    () =>
      data
        ? {
            ...publicData,
            courseProgress: pipe(
              propOr([], 'courseProgressesForUser'),
              reduce((acc, item) => {
                acc[item.id] = publicData.courses[item.course.id];

                return acc;
              }, {})
            )(data),
            loading: publicData.loading || loading || Boolean(error),
          }
        : publicData,
    [data, error, loading, publicData]
  );
};

export const useMetaData = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const metaData = useMemo(() => getStaticMetaData(t), [t]);

  const { loading, courses, programs, posts, courseProgress = {} } = useRequest();

  const meta = useMemo(
    () =>
      pipe(
        splitPathname,
        cond([
          [pipe(length, equals(0)), () => prop('default', metaData)],
          [pipe(length, equals(1)), pathOr(prop('default', metaData), __, metaData)],
          [pipe(head, equals('account')), () => prop('account', metaData)],
          [pipe(head, equals('kurse')), pipe(prop(1), prop(__, courses))],
          [pipe(head, equals('videos')), pipe(prop(1), prop(__, posts))],
          [pipe(head, equals('themen')), pipe(prop(1), prop(__, programs))],
          [pipe(props([0, 1]), equals(['books', 'generation-y'])), () => prop('generation-y', metaData)],
          [pipe(props([0, 1]), equals(['books', 'inflation'])), () => prop('inflation', metaData)],
          [pipe(props([0, 1]), equals(['books', 'corona-crash'])), () => prop('corona-crash', metaData)],
          [pipe(props([0, 1]), equals(['books', 'moneyfactory'])), () => prop('moneyfactory', metaData)],
          [pipe(props([0, 1]), equals(['books', 'wenn-banken-wanken'])), () => prop('wenn-banken-wanken', metaData)],
          [pipe(props([0, 1]), equals(['books', 'die-aktienboerse'])), () => prop('die-aktienboerse', metaData)],
          [pipe(props([0, 1]), equals(['books', 'waehrungen'])), () => prop('waehrungen', metaData)],
          [pipe(props([0, 1]), equals(['books', 'suche-nach-reichtum'])), () => prop('suche-nach-reichtum', metaData)],
          [pipe(props([0, 1]), equals(['books', 'money-combo'])), () => prop('money-combo', metaData)],
          [pipe(props([0, 1]), equals(['books', 'money-combo-2'])), () => prop('money-combo-2', metaData)],
          [pipe(props([0, 1]), equals(['books', 'die-schweiz-sorgt-vor'])), () => prop('die-schweiz-sorgt-vor', metaData)],
          [pipe(props([0, 1]), equals(['books', 'glaenzende-aussichten'])), () => prop('glaenzende-aussichten', metaData)],
          [pipe(head, includes('geschenkkarte')), () => prop('geschenkkarte', metaData)],
          [pipe(props([0, 1]), equals(['akademie', 'kurse'])), pipe(prop(2), prop(__, courseProgress))],
          [pipe(head, anyPass([equals('business'), equals('b2b')])), pipe(prop(0), prop(__, metaData))],
          [T, () => prop('default', metaData)],
        ])
      )(pathname),
    [pathname, courses, programs, posts, courseProgress, metaData]
  );

  if (loading) return metaData.default;

  return meta || metaData.default;
};
