import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import ErrorBoundary from '../SuspenseErrorBoundary';

const PrivatableRoute = ({ component: Component, auth }) =>
  auth ? (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <Navigate to="/akademie" />
  );

PrivatableRoute.defaultProps = {
  auth: false,
};

PrivatableRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  auth: PropTypes.bool,
};

export default PrivatableRoute;
