import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import GlobalStyle from 'theme/GlobalStyles';

import Helmet from './Helmet';
import InitApp from './InitApp';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import SubscribeMessage from './SubscribeMessage';
// import CookieWarn from './CookieWarn';

const Layout = () => {
  const { pathname } = useLocation();

  if (/^\/business|^\/b2b/.test(pathname))
    return (
      <>
        <Helmet />
        <GlobalStyle />
        <Outlet />
      </>
    );

  return (
    <>
      <Helmet />
      <GlobalStyle />
      <Header />
      <SubscribeMessage />
      <InitApp>
        <Main>
          <Outlet />
        </Main>
        <Footer />
        {/* <CookieWarn /> */}
      </InitApp>
    </>
  );
};

export default Layout;
