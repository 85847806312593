import { lazy } from 'react';

import { ALLOW_CHANGE_LANGUAGE } from 'locale/consts';

const Intro = lazy(() => import('containers/courses/Pages/Intro'));
const LoginPage = lazy(() => import('containers/authorization/Pages/Login'));
const ActivateAccountPage = lazy(() => import('containers/authorization/Pages/ActivateAccount'));
const SignUpPage = lazy(() => import('containers/authorization/Pages/SignUp'));
const RequestResetPasswordPage = lazy(() => import('containers/authorization/Pages/RequestResetPassword'));
const ResetPasswordPage = lazy(() => import('containers/authorization/Pages/ResetPassword'));
const CoursePage = lazy(() => import('containers/course/Pages/Course'));
const ProgramCollection = lazy(() => import('containers/collection/Pages/ProgramColllection'));
const Program = lazy(() => import('containers/program/Pages/Program'));
const Talks = lazy(() => import('containers/posts/Pages/Talks'));
const TalkDetails = lazy(() => import('containers/post/Pages/TalkDetails'));
const Events = lazy(() => import('containers/events/Pages/Events'));
const Books = lazy(() => import('containers/books/Pages/Books'));
const BooksOverview = lazy(() => import('containers/books/Pages/Books/Overview'));
const MoneyComboBook1 = lazy(() => import('containers/books/Pages/Books/MoneyCombo1'));
const MoneyComboBook2 = lazy(() => import('containers/books/Pages/Books/MoneyCombo2'));
const SwitzerlandTakesCareBook = lazy(() => import('containers/books/Pages/Books/SwitzerlandTakesCare'));
const BrightProspects = lazy(() => import('containers/books/Pages/Books/BrightProspects'));
const LookingForWealth = lazy(() => import('containers/books/Pages/Books/LookingForWealth'));
const Currencies = lazy(() => import('containers/books/Pages/Books/Currencies'));
const StockExchange = lazy(() => import('containers/books/Pages/Books/StockExchange'));
const BanksFalter = lazy(() => import('containers/books/Pages/Books/BanksFalter'));
const GenerationY = lazy(() => import('containers/books/Pages/Books/GenerationY'));
const Inflation = lazy(() => import('containers/books/Pages/Books/Inflation'));
const CoronaBook = lazy(() => import('containers/books/Pages/Books/CoronaCrash'));
const MoneyFactoryBook = lazy(() => import('containers/books/Pages/Books/MoneyFactory'));
const GiftCard = lazy(() => import('containers/books/Pages/GiftCard'));
const CompendioBook = lazy(() => import('containers/compendio/Pages/Book'));
const CoursesPage = lazy(() => import('containers/courses/Pages/Courses'));
const Imprint = lazy(() => import('components/FooterLink/Imprint'));
const Support = lazy(() => import('components/FooterLink/Support'));
const Terms = lazy(() => import('components/FooterLink/Terms'));
const Privacy = lazy(() => import('components/FooterLink/Privacy'));
const Team = lazy(() => import('components/FooterLink/Team'));
const Media = lazy(() => import('components/FooterLink/Media'));
const Partner = lazy(() => import('components/FooterLink/Partner'));
const AccountPage = lazy(() => import('containers/account/Pages/Account'));
const OwnCourses = lazy(() => import('containers/courses/Pages/OwnCourses'));
const LecturePage = lazy(() => import('containers/lectures/Pages/Lectures'));
const AssessmentPage = lazy(() => import('containers/assessments/Pages/Assessment'));
const ExternalNewsLetter = lazy(() => import('containers/account/Pages/ExternalNewsLetter'));
const CourseList = lazy(() => import('containers/courses/Pages/OwnCourses/CourseList'));
const RecommendationList = lazy(() => import('containers/courses/Pages/OwnCourses/RecommendationList'));
const BooksList = lazy(() => import('containers/books/Pages/BooksList'));
const PersonalInfo = lazy(() => import('containers/account/Pages/Account/PersonalInfo'));
const ChangePassword = lazy(() => import('containers/account/Pages/Account/ChangePassword'));
const ChangeEmail = lazy(() => import('containers/account/Pages/Account/ChangeEmail'));
const Settings = lazy(() => import('containers/account/Pages/Account/Settings'));
const Membership = lazy(() => import('containers/account/Pages/Account/Membership'));
const Payments = lazy(() => import('containers/account/Pages/Account/Payments'));
const PaymentHistory = lazy(() => import('containers/account/Pages/Account/PaymentHistory'));
const Recommend = lazy(() => import('containers/account/Pages/Account/Recommend'));
const Credits = lazy(() => import('containers/account/Pages/Account/Credits'));
const Language = lazy(() => import('containers/account/Pages/Account/Language'));
const BusinessLayout = lazy(() => import('containers/business/Pages/Layout'));
const Business = lazy(() => import('containers/business/Pages/Business'));
const BusinessContact = lazy(() => import('containers/business/Pages/Contact'));
const SmartBook = lazy(() => import('components/SmartBook'));
const VideoLetter = lazy(() => import('containers/account/Pages/VideoLetter'));

export const ROUTES = [
  { path: '/intro', component: Intro },
  { path: '/akademie', component: CoursesPage },
  { path: '/akademie/kurse/:courseProgressId/lektionen/:lectureId', component: LecturePage, isPrivate: true },
  { path: '/akademie/kurse/:courseProgressId/assessments/:examinationId/fragen', component: AssessmentPage, isPrivate: true },
  {
    path: '/akademie/kurse/:courseProgressId/assessments/:examinationId/fragen/:questionId',
    component: AssessmentPage,
    isPrivate: true,
  },
  { path: '/anmelden', component: LoginPage },
  { path: '/account-aktivieren', component: ActivateAccountPage },
  { path: '/registrieren', component: SignUpPage },
  { path: '/passwort-vergessen', component: RequestResetPasswordPage },
  { path: '/passwort-setzen', component: ResetPasswordPage },
  { path: '/kurse/:courseId/:courseUrlParam', component: CoursePage },
  { path: '/themen/:programId/:programUrlParam', component: Program },
  { path: '/videos', component: Talks },
  { path: '/videos/:postId/:postUrlParam', component: TalkDetails },
  { path: '/events', component: Events },
  {
    path: '/books',
    component: Books,
    subItems: [
      { component: BooksOverview },
      { path: 'glaenzende-aussichten', component: BrightProspects },
      { path: 'suche-nach-reichtum', component: LookingForWealth },
      { path: 'waehrungen', component: Currencies },
      { path: 'die-aktienboerse', component: StockExchange },
      { path: 'wenn-banken-wanken', component: BanksFalter },
      { path: 'generation-y', component: GenerationY },
      { path: 'inflation', component: Inflation },
      { path: 'corona-crash', component: CoronaBook },
      { path: 'moneyfactory', component: MoneyFactoryBook },
      { path: 'money-combo', component: MoneyComboBook1 },
      { path: 'money-combo-2', component: MoneyComboBook2 },
      { path: 'die-schweiz-sorgt-vor', component: SwitzerlandTakesCareBook },
    ],
  },
  { path: '/geschenkkarte', component: GiftCard },
  { path: '/compendio', component: CompendioBook },
  { path: '/newsletter', component: ExternalNewsLetter },
  { path: '/impressum', component: Imprint },
  { path: '/support', component: Support },
  { path: '/support/:sectionId', component: Support },
  { path: '/support/:sectionId/:id', component: Support },
  { path: '/datenschutz', component: Privacy },
  { path: '/agb', component: Terms },
  { path: '/team', component: Team },
  { path: '/medien', component: Media },
  { path: '/partner', component: Partner },
  {
    path: '/account',
    component: AccountPage,
    isPrivate: true,
    subItems: [
      { component: PersonalInfo },
      { path: 'passwort-aendern', component: ChangePassword },
      { path: 'email-aendern', component: ChangeEmail },
      { path: 'kontoeinstellungen', component: Settings },
      { path: 'abo', component: Membership },
      { path: 'zahlungsmethode', component: Payments },
      { path: 'zahlungsverlauf', component: PaymentHistory },
      { path: 'fintool-empfehlen', component: Recommend },
      { path: 'fintool-credits', component: Credits },
      ...(ALLOW_CHANGE_LANGUAGE ? [{ path: 'sprache', component: Language }] : []),
    ],
  },
  {
    path: '/meine-kurse',
    component: OwnCourses,
    isPrivate: true,
    subItems: [
      { component: CourseList, props: { status: 1 } },
      { path: 'abgeschlossen', component: CourseList, props: { status: 2 } },
      { path: 'empfehlungen', component: RecommendationList },
      { path: 'books', component: BooksList },
    ],
  },
  { path: '/collection/:programCollectionId', component: ProgramCollection, isPrivate: true },
  { path: '/smartbooks/:bookId', component: SmartBook },
  {
    path: '/business',
    component: BusinessLayout,
    subItems: [{ component: Business }, { path: 'contact', component: BusinessContact }],
  },
  {
    path: '/b2b',
    component: BusinessLayout,
    subItems: [{ component: Business }, { path: 'contact', component: BusinessContact }],
  },
  { path: '/videoletter', component: VideoLetter },
];

export { Talks as IndexPage };
